<template>
  <form @submit.prevent="submit">
    <div class="p-mb-3 p-input-filled">
      <div class="p-field">
        <InputText
          placeholder="Email"
          type="text"
          class="project-name"
          v-model="formData.email"
          @blur="validation.formData.email.$touch()"
          :class="{ 'p-invalid': validation.formData.email.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.email"
          :rules="['required', 'email']"
        />
      </div>
    </div>
    <Button
      type="submit"
      class="submit"
      :disabled="disabled || validation.$invalid"
      label="Save"
    />
  </form>
</template>

<script lang="ts">
import { ref, defineComponent, watchEffect } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'

export default defineComponent({
  components: { Button, InputText, BaseValidationErrors },
  props: {
    email: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      email: ''
    })

    const rules = {
      formData: {
        email: { required, email }
      }
    }

    const validation = useVuelidate(rules, { formData })

    const submit = () => {
      validation.value.$touch()
      if (!validation.value.$invalid) emit('submitted', formData.value.email)
    }

    watchEffect(() => {
      if (props.email) {
        formData.value.email = props.email
      }
    })

    return { formData, submit, validation }
  }
})
</script>
