<template>
  <form @submit.prevent="submit">
    <input type="hidden" name="username" :value="email" />
    <div class="p-mb-3 p-input-filled">
      <div class="p-field">
        <InputText
          type="password"
          autocomplete="new-password"
          placeholder="Password"
          class="project-name"
          v-model="formData.password"
          @blur="validation.formData.password.$touch()"
        />
        <BaseValidationErrors
          :field="validation.formData.password"
          :rules="['required', 'minLength']"
        />
      </div>
      <div class="p-field">
        <InputText
          type="password"
          autocomplete="new-password"
          placeholder="Repeat Password"
          class="project-name"
          v-model="formData.confirmPassword"
          @blur="validation.formData.confirmPassword.$touch()"
        />
        <BaseValidationErrors
          :field="validation.formData.confirmPassword"
          :rules="['isSame']"
          message="Passwords do not match"
        />
      </div>
    </div>
    <Button
      type="submit"
      class="submit"
      :disabled="disabled || validation.$invalid"
      label="Save"
    />
  </form>
</template>

<script>
import { ref, defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'

export default defineComponent({
  components: { Button, InputText, BaseValidationErrors },
  props: {
    email: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      password: '',
      confirmPassword: ''
    })

    const isSame = val => {
      return val === formData.value.password
    }

    const rules = {
      formData: {
        password: { required, minLength: minLength(6) },
        confirmPassword: { isSame }
      }
    }

    const validation = useVuelidate(rules, { formData })

    const submit = () => {
      validation.value.$touch()
      if (!validation.value.$invalid) {
        emit('submitted', formData.value.password)
      }
    }

    return { formData, submit, validation }
  }
})
</script>
