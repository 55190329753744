<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>My Account</h1>
  <p v-if="user">
    <b>Name</b>: {{ user.name }}<br />
    <b>Email</b>: {{ user.email }}<br />
    <b>Roles</b>: {{ user.roles }}<br />
    <b>ID</b>: {{ user.id }}
  </p>
  <Button
    label="Update Email Address"
    @click="onShowEmailClick"
    class="p-button-outlined"
  />
  &nbsp;
  <Button
    label="Update Password"
    @click="onShowPasswordClick"
    class="p-button-outlined"
  />
  <Dialog
    header="Update Email Address"
    v-model:visible="showEmailForm"
    :dismissableMask="true"
    :modal="true"
  >
    <UserEmailForm
      @submitted="changeEmail"
      :email="user.email"
      :disabled="disableEmailForm"
    />
  </Dialog>
  <Dialog
    header="Update Password"
    v-model:visible="showPasswordForm"
    :dismissableMask="true"
    :modal="true"
  >
    <UserPasswordForm
      @submitted="changePassword"
      :email="user.email"
      :disabled="disablePasswordForm"
    />
  </Dialog>
</template>

<script lang="ts">
import { ref, watchEffect, defineComponent } from 'vue'
import { useStore } from 'vuex'
import UserEmailForm from '@/components/UserEmailForm.vue'
import UserPasswordForm from '@/components/UserPasswordForm.vue'
import { auth, firestore } from '@/services/firebase.ts'
import { useToast } from 'primevue/usetoast.js'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default defineComponent({
  components: {
    UserEmailForm,
    UserPasswordForm,
    Dialog,
    Button,
    BaseBreadcrumb
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const user = ref({ id: '' })
    const showEmailForm = ref(false)
    const showPasswordForm = ref(false)
    const disableEmailForm = ref(false)
    const disablePasswordForm = ref(false)
    const breadcrumbs = [{ label: 'My Account' }]

    watchEffect(() => {
      user.value = store.getters.user
    })

    const changeEmail = async (email: string) => {
      if (auth.currentUser && user.value.id) {
        disableEmailForm.value = true
        try {
          // update firebase user email
          await auth.currentUser.updateEmail(email)

          // update user document
          await firestore
            .collection('users')
            .doc(user.value.id)
            .set({ email }, { merge: true })

          // update store
          await store.dispatch('fetchUser')

          showEmailForm.value = false
          disableEmailForm.value = false

          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Email updated',
            life: 3000
          })
        } catch (error) {
          showEmailForm.value = false
          disableEmailForm.value = false

          toast.add({
            severity: 'error',
            summary: 'Failed',
            detail: error.message,
            life: 3000
          })
        }
      }
    }

    const changePassword = async (password: string) => {
      if (auth.currentUser) {
        disablePasswordForm.value = true
        try {
          // update firebase user email
          await auth.currentUser.updatePassword(password)

          showPasswordForm.value = false
          disablePasswordForm.value = false

          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Password updated',
            life: 3000
          })
        } catch (error) {
          showPasswordForm.value = false
          disablePasswordForm.value = false

          toast.add({
            severity: 'error',
            summary: 'Failed',
            detail: error.message,
            life: 3000
          })
        }
      }
    }

    const onShowEmailClick = () => {
      showEmailForm.value = true
      showPasswordForm.value = false
    }
    const onShowPasswordClick = () => {
      showEmailForm.value = false
      showPasswordForm.value = true
    }

    return {
      user,
      changeEmail,
      changePassword,
      showEmailForm,
      showPasswordForm,
      onShowEmailClick,
      onShowPasswordClick,
      disableEmailForm,
      disablePasswordForm,
      breadcrumbs
    }
  }
})
</script>
