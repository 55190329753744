
import { ref, defineComponent, watchEffect } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'

export default defineComponent({
  components: { Button, InputText, BaseValidationErrors },
  props: {
    email: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      email: ''
    })

    const rules = {
      formData: {
        email: { required, email }
      }
    }

    const validation = useVuelidate(rules, { formData })

    const submit = () => {
      validation.value.$touch()
      if (!validation.value.$invalid) emit('submitted', formData.value.email)
    }

    watchEffect(() => {
      if (props.email) {
        formData.value.email = props.email
      }
    })

    return { formData, submit, validation }
  }
})
